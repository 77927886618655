import { Component, computed, DestroyRef, effect, OnChanges, OnDestroy, OnInit, signal, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FieldType, GUIDE_TREE, GuideIconLocation, IField, IFormChange, IModule, IPage, IRecord, IRecordCreateContract, IRecordUpdateContract, MessageExchange, RecordExchangeEvent } from '@soleran/contracts';
import { isDefined } from '@soleran/ngx-common';
import { FormMode, resetState, setFormMode, } from '@soleran/ngx-layout-utility';
import { ModuleService } from '@soleran/ngx-module';
import { PageService } from '@soleran/ngx-page';
import { RecordService } from '@soleran/ngx-record';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, NEVER, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import pluralize from 'pluralize';
import { ChatInitializerService, ChatService, GlobalsocketService } from '@soleran/ngx-chat';
import { toRecordUpdateContract } from '../util/to-record-update-contract';
import { LayoutActions } from 'src/app/shared/_state/layout/actions';
import { ObjectActions } from 'src/app/shared/_state/object/actions';
import { EventService } from '@soleran/ngx-event';
import { isEqual } from 'lodash';

interface IRecordPageData {
	module: IModule,
	record: IRecord
}

@Component({
	selector: 'app-record-page',
	templateUrl: './record-page.component.html',
	styleUrls: ['./record-page.component.scss']
})
export class RecordPageComponent implements OnInit, OnDestroy {
	module$: Observable<IModule> = NEVER;
	record$: Observable<IRecord> = NEVER;
	recordSubject$ = new BehaviorSubject<IRecord | null>(null);
	page$: Observable<IPage> = NEVER;
	formMode$: Observable<FormMode> = NEVER;
	templateData$: Observable<IRecordPageData> = NEVER;

	formMode = FormMode.View
	formState?: IFormChange<IRecord>;
	messageTrayExpanded = false;

	EDIT = FormMode.Edit;
	VIEW = FormMode.View

	record: string;
	object: string;

	loading = signal<boolean>(true);
	childLoading = signal<boolean>(true);
	isLoading = computed(() => this.loading() || this.childLoading());

	unreadChatCount = 0;
	private currentFetchedRoomId: string | null = null;
	recordId$: Observable<string>;

	guideIconLocation = GuideIconLocation.RECORD;
	guideIconLocationEdit = GuideIconLocation.RECORD_EDIT;

	constructor(
		private _route: ActivatedRoute,
		private _store: Store,
		private _moduleService: ModuleService,
		private _recordService: RecordService,
		private _pageService: PageService,
		private _destroyRef: DestroyRef,
		private _globalsocketService: GlobalsocketService,
		private _chatInitializerService: ChatInitializerService,
		private _chatService: ChatService,
		private _eventService: EventService
	) { }

	ngOnInit(): void {
		this._initModule();
		this._initRecord();
		this._initPage();
		this._initPageBuilder();
		this._initTemplateData();
		this._initChat();
		this._initRecordEvent()
	}
	private _initRecordEvent() {
		this.recordId$.pipe(
			takeUntilDestroyed(this._destroyRef),
			mergeMap(recordId => {
				return this._eventService.getSocket({ namespace: MessageExchange.RECORD })
					.listen<IRecord>(RecordExchangeEvent.CS_UPDATE)
					.pipe(
						filter(event => event.data.id === +recordId),
						map(event => event.data),
						filter(record => !isEqual(record, this.recordSubject$.value))
					);
			})
		).subscribe(record => this.recordSubject$.next(record));
	}
	
	ngOnDestroy(): void {
		this._store.dispatch(resetState());
	}
	onRecordFormLoadingChange(loading: boolean) {
		this.childLoading.set(false);
	}
	toggleMessageTray() {
		this.messageTrayExpanded = !this.messageTrayExpanded;
	}
	private _initTemplateData() {
		this.templateData$ = combineLatest([this.module$, this.record$]).pipe(
			map(([module, record]) => {
				const templateData: IRecordPageData = {
					module,
					record
				};
				return templateData;
			}),
			tap(_ => this.loading.set(false))
		)
	}
	onFormChange(change: IFormChange<IRecord>) {
		this.formState = change;
	}
	pluralize(singular: string) {
		return pluralize(singular);
	}
	private _initRecord() {
		this.record$ = this.recordSubject$.asObservable().pipe(
			tap(record => this._store.dispatch(LayoutActions.setRecord({ record })))
		);
		this.recordId$ = this._route.paramMap.pipe(map(paramMap => paramMap.get('recordId')));
		combineLatest([this.module$, this.recordId$]).pipe(
			tap(_ => this.loading.set(true)),
			takeUntilDestroyed(this._destroyRef),
			mergeMap(([module, recordId]) => {
				return this._recordService.get(recordId, module.id).pipe(map((response: any) => response));
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		).subscribe(record => this.recordSubject$.next(record));
	}
	onEdit() {
		this.formMode = FormMode.Edit;
	}
	onCancel() {
		this.formMode = FormMode.View;
	}
	onSave(data: IRecordPageData) {
		const formData = this.formState?.value;
		const createModel: IRecordCreateContract = toRecordUpdateContract(data.module, formData);
		this._recordService.update(data.record.id, data.module.id, createModel).pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(record => {
			this.recordSubject$.next(record);
			this.formMode = FormMode.View;
		})
	}
	getKeyField(data: IRecordPageData) {
		const keyField = data.module.keyField;
		const record = data.record;
		return record ? record[keyField] : '';
	}
	private _initPage() {
		this.page$ = this.module$.pipe(
			mergeMap(module => this._pageService.get().pipe(map(pages => pages.find(page => page.moduleId === module.id)), filter(isDefined)))
		)
	}
	private _initPageBuilder() {
		combineLatest([this.page$, this.record$]).pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(([page, record]) => {
			const mode = FormMode.View;
			this._store.dispatch(setFormMode({ mode }));
		})
	}
	private _initModule() {
		this.module$ = this._route.paramMap.pipe(
			tap(_ => this.loading.set(true)),
			map(paramMap => paramMap.get('id')),
			switchMap(moduleId => this._moduleService.get(moduleId)),
			tap(module => this._store.dispatch(ObjectActions.setSelectedObject({ selectedObject: module })))
		)
	}

	private _initChat() {
		this._chatInitializerService.init();
		this._globalsocketService.joinGlobalRoom();

		combineLatest([this.module$, this.record$])
			.pipe(
				filter(([module, record]) => !!module && !!record),
				takeUntilDestroyed(this._destroyRef),
				switchMap(([module, record]) => {
					this.object = module.id;
					this.record = record.id.toString();
					return this._chatService
						.getRoomByThreadIdentifiers(this.object, this.record);
				}),
				switchMap((roomId: string | null) => {
					if (!roomId) {
						console.warn('No existing room found. Possibly create one, or skip fetching details.');
						return of(null);
					}
					return this._chatService.getRoomDetails(roomId);
				})
			)
			.subscribe({
				next: (roomDetails) => {
					if (roomDetails) {
						this._chatService
							.getUnreadCount(this._chatService.currentUserId)
							.pipe(
								tap((unread) => {
									this.currentFetchedRoomId = roomDetails.roomDetails._id;
									const matched = unread.find((u) => u._id === this.currentFetchedRoomId);
									this.unreadChatCount = matched ? matched.count : 0;
								})
							)
							.subscribe();
					}
				},
				error: (error) => console.error('Error initializing chat:', error),
			});

		// 5) Also handle message notifications if needed
		this._globalsocketService.messageNotification$
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe({
				next: (notification) => {
					const incomingRoomId = notification.message.roomId;
					if (incomingRoomId === this.currentFetchedRoomId) {
						this.unreadChatCount++;
					}
				},
				error: (err) => console.error('Error in messageNotification$', err),
			});

		this._globalsocketService.readReceipt$
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe({
				next: (readReceipt) => {
					if (readReceipt && Array.isArray(readReceipt.readReceipt)) {
						const userJustRead = readReceipt.readReceipt.find(
							(r: any) => r.userId === this._chatService.currentUserId && r.status === 'read'
						);

						if (userJustRead) {
							this._chatService
								.getUnreadCount(this._chatService.currentUserId)
								.pipe(
									tap((unreadArray) => {
										const matched = unreadArray.find((u) => u._id === this.currentFetchedRoomId);
										this.unreadChatCount = matched ? matched.count : 0;
									})
								)
								.subscribe();
						}
					}
				}
			})
	}
}


