import { IModule, IRecordUpdateContract } from "@soleran/contracts";
import { toUpdateValue } from "./to-record-update-value";

export const toRecordUpdateContract = (module: IModule, data: any) => {
	const model = module.fields.reduce((model, field) => {
		const value = data[field.id];
		const updateValue = toUpdateValue(field, value);
		model[field.id] = updateValue;
		return model;
	}, {} as IRecordUpdateContract)
	return model;
}