import { Component, DestroyRef, effect, EventEmitter, input, Input, OnChanges, OnInit, Output, signal, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { IDataRole, IFormChange, ILayout, ILayoutVersion, IModule, IPage, IRecord, LayoutVersionStatus } from '@soleran/contracts';
import { LayoutService, LayoutVersionService } from '@soleran/ngx-layout';
import { FormMode, IPageBuilderState, LayoutFormService, PageBuilderActions } from '@soleran/ngx-layout-utility';
import { PageService } from '@soleran/ngx-page';
import { combineLatest, map, NEVER, Observable, switchMap, tap } from 'rxjs';

interface IRecordFormData {
	page: IPage,
	module: IModule,
	record?: IRecord,
	layout: ILayout,
	layoutVersion: ILayoutVersion
}

@Component({
	selector: 'app-record-form',
	templateUrl: './record-form.component.html',
	styleUrls: ['./record-form.component.scss']
})
export class RecordFormComponent implements OnInit, OnChanges {
	@Input() formMode: FormMode = FormMode.View;
	@Output() formChange = new EventEmitter<IFormChange<IRecord>>()
	@Output() isLoading = new EventEmitter<boolean>();

	module = input.required<IModule>();
	record = input<IRecord>();

	module$ = toObservable(this.module);
	record$ = toObservable(this.record);

	loading = signal<boolean>(true);
	childLoading = signal<boolean>(true);

	page$: Observable<IPage> = NEVER;
	dataRole$: Observable<IDataRole> = NEVER;
	layout$: Observable<ILayout> = NEVER;
	layoutVersion$: Observable<ILayoutVersion> = NEVER;
	templateData$: Observable<IRecordFormData> = NEVER;

	constructor(
		private _pageService: PageService,
		private _layoutService: LayoutService,
		private _layoutVersionService: LayoutVersionService,
		private _formService: LayoutFormService,
		private _destroyRef: DestroyRef,
		private _store: Store<IPageBuilderState>
	) {
		effect(() => {
			const isLoading = this.loading() && this.childLoading();
			this.isLoading.emit(isLoading)
		})
	}

	ngOnInit(): void {
		this._initForm();
		this._initPage();
		// this._initDataRole();
		this._initLayout();
		this._initLayoutVersion();
		this._initTemplateData();
	}
	onPageLoadingChange(loading: boolean) {
		this.childLoading.set(loading);
	}
	private _initDataRole() {
		throw new Error('Method not implemented.');
	}
	private _initLayout() {
		this.layout$ = this.page$.pipe(
			switchMap(page => this._layoutService.getByPageId(page.id)),
			map(layouts => layouts.at(0))
		)
	}
	private _initLayoutVersion() {
		this.layoutVersion$ = this.layout$.pipe(
			switchMap(layout => this._layoutVersionService.getByLayoutId(layout.id)),
			map(versions => versions.find(version => +version.status === LayoutVersionStatus.Active))
		)
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['record'] && !changes['record'].firstChange)
			this._store.dispatch(PageBuilderActions.setRecord({ record: changes['record'].currentValue }))
		if (changes['formMode'] && !changes['formMode'].firstChange)
			this._store.dispatch(PageBuilderActions.setFormMode({ mode: changes['formMode'].currentValue }))
	}
	getFormModeDisplay(formMode: FormMode) {
		return FormMode[formMode]
	}
	private _initPage() {
		this.page$ = this.module$.pipe(
			tap(_ => this.loading.set(true)),
			switchMap(module => this._pageService.getByModuleId(module.id))
		)
	}
	private _initTemplateData() {
		this.templateData$ = combineLatest([this.module$, this.record$, this.page$, this.layout$, this.layoutVersion$]).pipe(
			map(([module, record, page, layout, layoutVersion]) => {
				const templateData: IRecordFormData = {
					module,
					record,
					page,
					layout,
					layoutVersion
				};
				return templateData;
			}),
			tap(_ => this.loading.set(false))
		)
	}
	private _initForm() {
		this._formService.observeValueChanges().pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(change => {
			this.formChange.emit(change);
		})
	}
}
