import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordPageComponent } from './record-page.component';
import { RecordPageMaterialModule } from './record-page.module.material';
import { RouterModule, Routes } from '@angular/router';
import { ModuleModule, RecordModule } from '@soleran/ngx-module';
import { FormModule } from '@soleran/ngx-form';
import { PageModule } from '@soleran/ngx-layout-utility';
import { LayoutModule } from '@soleran/ngx-layout';
import { RecordFormComponent } from '../record-form/record-form.component';
import { RecordEditComponent } from '../record-edit/record-edit.component';
import { RecordCreateComponent } from '../record-create/record-create.component';
import { ChatModule, ChatRoomModule } from '@soleran/ngx-chat';
import { ShimmerModule, TruncateTooltipModule } from '@soleran/ngx-common';
import { GuideModule } from '@soleran/ngx-template';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../record/records-page/records-page.module').then((m) => m.RecordsPageModule)
  },
	{
		path: 'create',
		component: RecordCreateComponent
	},
  {
    path: ':recordId',
    component: RecordPageComponent
  }
];

@NgModule({
  declarations: [
    RecordPageComponent,
    RecordFormComponent,
    RecordEditComponent,
    RecordCreateComponent
  ],
  imports: [
    CommonModule,
    RecordPageMaterialModule,
    ModuleModule,
    RecordModule,
    PageModule,
    LayoutModule,
    RouterModule.forChild(routes),
    ShimmerModule,
    FormModule,
    ChatRoomModule,
    TruncateTooltipModule,
    GuideModule,
  ],
  exports: [
    RecordEditComponent,
    RecordCreateComponent,
    RecordFormComponent
  ]
})
export class RecordPageModule { }
