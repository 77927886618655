<div class="container" [ngClass]="{ 'hidden': isLoading()}" *ngIf="templateData$ | async as data">
    <mat-toolbar class="toolbar">
        <div class="toolbar-left" matTooltip [solTruncateTooltip]="'20rem'">
            {{ getKeyField(data) }}
        </div>
        <div class="toolbar-right">
            <sol-guide [_guideIconLocation]="formMode === VIEW ? guideIconLocation : guideIconLocationEdit"></sol-guide>
            <button [color]="'primary'" mat-icon-button (click)="toggleMessageTray()" [matTooltip]="'Open chat'"
                [matTooltipShowDelay]="350">
                <mat-icon class="material-symbols-outlined icon-faded no-fill" [matBadge]="unreadChatCount" matBadgePosition="above after" matBadgeOverlap="false" [matBadgeHidden]="unreadChatCount === 0" [matBadgeSize]="'small'">chat</mat-icon>
            </button>
            <button mat-icon-button color="primary" *ngIf="formMode === VIEW"
                [matTooltip]="'View All ' + pluralize(data.module.name)" [matTooltipShowDelay]="350"
                [routerLink]="['..']"><mat-icon class="material-symbols-outlined icon-faded no-fill">
                    list
                </mat-icon></button>
            <button mat-icon-button color="primary" *ngIf="formMode === VIEW" [matTooltip]="'Add ' + data.module.name"
                [matTooltipShowDelay]="350" [routerLink]="['..', 'create']"><mat-icon
                    class="material-symbols-outlined icon-faded no-fill">add</mat-icon></button>
            <button mat-icon-button color="primary" *ngIf="formMode === VIEW" [matTooltip]="'Edit'"
                [matTooltipShowDelay]="350" (click)="onEdit()"><mat-icon
                    class="material-symbols-outlined icon-faded no-fill">edit</mat-icon></button>
            <button mat-icon-button *ngIf="formMode === EDIT" [matTooltip]="'Cancel'" [matTooltipShowDelay]="350"
                (click)="onCancel()"><mat-icon
                    class="material-symbols-outlined icon-faded no-fill">close</mat-icon></button>
            <button mat-icon-button color="primary" *ngIf="formMode === EDIT" [disabled]="!formState?.valid"
                [matTooltip]="'Save'" [matTooltipShowDelay]="350" (click)="onSave(data)"><mat-icon
                    class="material-symbols-outlined icon-faded no-fill">check</mat-icon></button>
        </div>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
        <mat-sidenav class="sidenav" mode="side" position="end" [opened]="messageTrayExpanded">
            <div class="sidenav-wrapper">
                <mat-divider vertical></mat-divider>
                <mat-tab-group class="tab-groups">
                    <mat-tab label="Chat">
                        <sol-chat-room [isThreadRoom]="true" [width]="'100%'" [height]="'87svh'" [objectId]="object"
                            [recordId]="record" [searchThreshold]="2000" [closed]="!messageTrayExpanded" />
                    </mat-tab>
                    <mat-tab label="Email">
                        <div class="construction-container">
                            <div class="construction-emoji">🚧</div>
                            <div class="construction-text">
                                <h1>Oops! This page is under construction</h1>
                                <p>We're working hard to bring this page to life. Check back soon!</p>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="sidenav-content">
            <div class="form">
                <app-record-form [module]="data.module" [record]="data.record" [formMode]="formMode"
                    (formChange)="onFormChange($event)"
                    (isLoading)="onRecordFormLoadingChange($event)"></app-record-form>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <!-- <div class="debug-section">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Record
                </mat-card-title>
            </mat-card-header>
            <pre class="json">
            {{ data.record | json }}
            </pre>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Form
                </mat-card-title>
            </mat-card-header>
            <pre class="json">
                {{ this.formState?.value | json }}
            </pre>
        </mat-card>
    </div> -->
</div>
<ng-container *ngIf="isLoading()">
    <mat-toolbar class="toolbar">
        <div class="toolbar-left">
            <sol-shimmer [heightRem]="2" [borderRadiusPx]="5"></sol-shimmer>
        </div>
        <div class="toolbar-right">
            <sol-shimmer [heightRem]="2" [borderRadiusPx]="100" *ngFor="let row of [0,1,2]"></sol-shimmer>
        </div>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <div class="shimmer-form">
        <div class="shimmer-container" *ngFor="let row of [0,1,2]">
            <div class="shimmer-row">
                <div class="shimmer-column">
                    <sol-shimmer [heightRem]="3" [borderRadiusPx]="10" *ngFor="let row of [0,1,2,4,5]"></sol-shimmer>
                </div>
                <div class="shimmer-column">
                    <sol-shimmer [heightRem]="3" [borderRadiusPx]="10" *ngFor="let row of [0,1,2]"></sol-shimmer>
                    <sol-shimmer [heightRem]="8" [borderRadiusPx]="10"></sol-shimmer>
                </div>
                <div class="shimmer-column">
                    <sol-shimmer [heightRem]="18" [borderRadiusPx]="10"></sol-shimmer>
                </div>
            </div>
        </div>
    </div>
</ng-container>